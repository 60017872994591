import request from '@/utils/request'

export const pageList: any = (data: any) => {
    return request({
        url: `/system/treat/search`,
        method: 'post',
        data
    })
}

export const getDetail: any = (id: string) => {
    return request({
        url: `/system/treat/detail/${id}`,
        method: 'get',
    })
}

export const saveOrUpdate: any = (data: any) => {
    return request({
        url: `/system/treat/saveOrUpdate`,
        method: 'post',
        data
    })
}

export const deleteTreat: any = (id: string) => {
    return request({
        url: `/system/treat/delete/${id}`,
        method: 'delete',
    })
}